import useLocalStorage from "@/hooks/useLocalStorageHook";
import { useEffect } from "react";
import {settingService} from "@/services/SettingService";

export const useSettingsConstants = <T, >(slug: string) => {
  const [storageSettings, setStorageSettings] = useLocalStorage<T>(slug, {});

  useEffect(() => {
    if (!(storageSettings && typeof storageSettings === 'object' && Object.keys(storageSettings).length > 0)) {
        settingService.getConstants(slug)
        .then(data => setStorageSettings(data[0]?.content ?? {} as T))
        .catch(e => console.log(e));
    }
  }, [slug]);

  return [storageSettings, setStorageSettings];
};