import {http} from "@/utils/http";
import {AxiosResponse} from "@/types/api/AxiosType";
import {
    BulkUpdateDeleteEntryType,
    UserRegulationsFormType,
    UserRegulationsType, UserUpdateRegulationsFormType,
} from "@/types/api/UserRegulationsType";
import {
    ADD_FETCH_DELETE_REGULATIONS_URL,
    ADD_FETCH_USER_REGULATIONS_URL,
    CANCEL_REGULATIONS_URL, GET_PATCH_DELETE_REGULATIONS_URL, PREVIOUS_REGULATIONS_URL
} from "@/constants/endpoints";

export const regulationService = {
    getAllDeductions: () => http.get<AxiosResponse<UserRegulationsType[]>>(ADD_FETCH_DELETE_REGULATIONS_URL),
    getAllIndemnities: () => http.get<AxiosResponse<UserRegulationsType[]>>(ADD_FETCH_DELETE_REGULATIONS_URL),
    getSingleUserRegulations: async (id: string, params?: {
        month?: string | null,
        type: string
    }, isNextDeduction: boolean = false) => {
        return http.get(ADD_FETCH_USER_REGULATIONS_URL.replace(":id", id), {
            params: isNextDeduction ? {
                ...params,
                filter_next: true
            } : params
        })
            .then(res => res.data.data as UserRegulationsType[]);
    },
    getAllUsersRegulations: async (params?: { month?: string | null, type: string }) => http
        .get(ADD_FETCH_DELETE_REGULATIONS_URL, {params})
        .then(res => res.data.data as UserRegulationsType[]),
    getUsersLastRegulations: async (params?: { month?: string | null, type: string }) => {
        return http.get(PREVIOUS_REGULATIONS_URL, {params})
            .then(res => res.data.data as UserRegulationsType[]);
    },
    cancelDeduction: async (deduction: Partial<BulkUpdateDeleteEntryType>) => http.request({
        method: 'patch',
        url: CANCEL_REGULATIONS_URL,
        data: deduction
    }),
    cancelIndemnity: async (indemnity: Partial<BulkUpdateDeleteEntryType>) => http.patch<AxiosResponse<BulkUpdateDeleteEntryType[]>>(CANCEL_REGULATIONS_URL, indemnity),
    createRegulation: async (regulations: Partial<UserRegulationsFormType>) => http.post<AxiosResponse<UserRegulationsType[]>>(ADD_FETCH_DELETE_REGULATIONS_URL, regulations),
    createDeduction: async (deduction: Partial<UserRegulationsFormType>) => http.post<AxiosResponse<UserRegulationsType>>(ADD_FETCH_DELETE_REGULATIONS_URL, deduction),
    createIndemnity: async (indemnity: Partial<UserRegulationsFormType>) =>
        http.post<AxiosResponse<UserRegulationsType>>(ADD_FETCH_USER_REGULATIONS_URL.replace(":id", `${indemnity.user}`), indemnity),
    updateRegulation: async (
        id?: string,
        data?: Partial<UserUpdateRegulationsFormType | FormData>
    ) => http.patch<AxiosResponse<UserRegulationsType>>(GET_PATCH_DELETE_REGULATIONS_URL.replace(":id", `${id}`), data)
}