import {http} from "@/utils/http";
import {Contract, User} from "@/types/UserType";
import {
    ADD_CONTRACT_TO_USER_URL,
    CANCEL_END_CONTRACT_URL,
    COMPLETE_USER_PROFILE_URL,
    FETCH_COMPLETE_USER_CONTRACT_URL,
    FETCH_CREATE_USERS_URL,
    FETCH_UPDATE_DELETE_USER_URL,
    UPDATE_USER_ADDITIONAL_INFOS_URL
} from "@/constants/endpoints";
import {AxiosResponse} from "axios";

export const userService = {
    getAll: () => http.get<User>(FETCH_CREATE_USERS_URL).then(response => response.data),
    getSingleUser: (id: string): Promise<User> => http.get<User>(`${FETCH_UPDATE_DELETE_USER_URL}`.replace(":id", id)).then(response => response.data),
    addUser: (user: FormData) => http.post(FETCH_CREATE_USERS_URL, user).then(response => response.data),
    updateUser: (user: FormData, id: string, step: number) => {
        return http.patch<User>(`${COMPLETE_USER_PROFILE_URL.replace(":id", id)}?step=${step}`, user)
            .then(response => response.data)
    },
    updateUserAdditionalInfos: (data: FormData, id: string) => http.patch<User>(`${UPDATE_USER_ADDITIONAL_INFOS_URL.replace(":id", id)}`, data).then(res => res.data),
    deleteUser: (id: string) => http.delete(FETCH_UPDATE_DELETE_USER_URL.replace(":id", id)).then(response => response.data),
    create: async (user: Partial<User>) => {
        return http.post<User>(FETCH_CREATE_USERS_URL, user)
            .then(response => response.data)
    }
}

export const contractService = {
    addContractToUser: (id: string, contract: FormData): Promise<AxiosResponse<User>> => {
        return http.post(ADD_CONTRACT_TO_USER_URL.replace(":id", id), contract)
            .then(response => response.data)
    },
    updateUserContract: (id: string, contract_id: string, step: number, contract: FormData) => http.patch(
        `${FETCH_COMPLETE_USER_CONTRACT_URL.replace(":id", id)
            .replace(":contract_id", contract_id)}?step=${step}`, contract)
        .then(response => response.data),
    cancelEndContract: (userId: string, contractId: string) => {
        return http.patch<AxiosResponse<Contract>>(CANCEL_END_CONTRACT_URL.replace(":id", userId).replace(":contract_id", contractId))
    }
}