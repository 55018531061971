import {http} from "@/utils/http";
import {
    FETCH_PAYSHEETS_GENERATION_JOBS,
    FETCH_PAYSHEETS_URL,
    FETCH_USER_PAYSHEETS_PDF_PATH_URL,
    FETCH_USER_PAYSHEETS_URL, FETCH_USER_SALARY_AVG, FETCH_USERS_LATEST_SALARY, FETCH_USERS_SALARY_AVG
} from "@/constants/endpoints";
import {JobType} from "@/types/SettingsType";
import {PaysheetLinesRef, UserPaysheet} from "@/types/paysheetDataType";
import {SettingsPaysheetLines} from "@/constants/settings";
import {settingService} from "@/services/SettingService";

export const paysheetService = {
    getPaysheetsGenerationJob: async () => {
        return http.get(FETCH_PAYSHEETS_GENERATION_JOBS)
            .then(res => res.data.data as JobType | undefined | null);
    },
    getUserPaysheets: async (id: string, params?: { month?: string, second_month?: string, }) => {
        return http
            .get(FETCH_USER_PAYSHEETS_URL.replace(":id", id), {
                params
            })
            .then(res => res.data.data as UserPaysheet[]);
    },
    getPaysheets: async (params?: { month?: string, second_month?: string, }) => {
        return http
            .get(FETCH_PAYSHEETS_URL, {
                params
            })
            .then(res => res.data.data as UserPaysheet[]);
    },
    getUsersPaysheetsByMonth: async (params: { month: string }) => {
        return http
            .get(FETCH_USER_PAYSHEETS_PDF_PATH_URL, {params})
            .then(res => res.data.data as [{ user_id: string, file: string }]);
    },
    getPaysheetLinesRef: async () => {
        return await settingService.getConstants(SettingsPaysheetLines) as PaysheetLinesRef[];
    },
    getUserSalaryAvg: async (id: string, params?: { limit: number }) => {
        return http
            .get(FETCH_USER_SALARY_AVG.replace(":id", id), {params})
            .then(res => res.data.data as number);
    },
    getUsersSalaryAvg: async (users: string[]) => {
        const res = http.post(FETCH_USERS_SALARY_AVG, {users}).then(res => res.data.data);
        return await res;
    },
    getUsersLatestSalaries: (id: string, params?: { limit: number }) => http
        .get(FETCH_USERS_LATEST_SALARY.replace(":id", id), {params})
        .then(res => res.data.data as { salary: number, month: string }[])
}