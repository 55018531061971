import {http} from "@/utils/http";
import {
    ADD_FETCH_REIMBURSEMENT_SALARY_ADVANCE_URL,
    ADD_FETCH_SALARY_ADVANCES_USERS_URL,
    FETCH_ALL_REIMBURSEMENTS_URL,
    FETCH_ALL_SALARY_ADVANCE_URL,
    FETCH_PATCH_DELETE_SALARY_ADVANCE_URL,
    FETCH_USER_REIMBURSEMENTS_URL,
    FETCH_USER_REMAINING_REIMBURSEMENT_URL,
    FETCH_USER_WITH_SALARY_ADVANCES_URL,
    FETCH_USERS_REMAINING_REIMBURSEMENTS_URL
} from "@/constants/endpoints";
import {getFormData} from "@/utils/utils";
import {AxiosResponse} from "@/types/api/AxiosType";
import {
    ReimbursementsType,
    RemainReimbursementType,
    SalaryAdvancesType,
    UserWithSalaryAdvance
} from "@/types/api/UserSalaryAdvancesType";


export const advanceService = {
    getAll: async (params?: { month?: string, filter_by?: string }) => {
        return http.get<AxiosResponse<SalaryAdvancesType[]>>(FETCH_ALL_SALARY_ADVANCE_URL, {params})
            .then(res => res.data.data as SalaryAdvancesType[]);
    },

    getByUserId: async (id: string, params?: { month?: string | number, year?: string | number }) => {
        return http.get<AxiosResponse<SalaryAdvancesType[]>>(ADD_FETCH_SALARY_ADVANCES_USERS_URL.replace(":id", `${id}`), {params})
            .then(res => res.data.data as SalaryAdvancesType[]);
    },

    delete: (id: string) => {
        return http.delete(FETCH_PATCH_DELETE_SALARY_ADVANCE_URL.replace(":id", id));
    },

    update: (id: string, data: any) => {
        return http.patch(FETCH_PATCH_DELETE_SALARY_ADVANCE_URL.replace(":id", id), getFormData(data));
    },

    create: async (advance: Partial<SalaryAdvancesType>) => {
        return http.post(ADD_FETCH_SALARY_ADVANCES_USERS_URL.replace(":id", `${advance.user}`), getFormData(advance));
    },
    // fetch user with salary advance
    getWithUser: async (params: { month?: string | number, year?: string | number } = {}) => {
        return http.get<AxiosResponse<UserWithSalaryAdvance[]>>(FETCH_USER_WITH_SALARY_ADVANCES_URL, {params});
    },

    getAllReimbursements: async (params?: { month: string }) => {
        return http.get<AxiosResponse<ReimbursementsType[]>>(FETCH_ALL_REIMBURSEMENTS_URL, {params})
            .then(res => res.data.data as ReimbursementsType[]);
    },

    getUserReimbursement: async (id: string, params?: { month: string }) => {
        return http
            .get(FETCH_USER_REIMBURSEMENTS_URL.replace(":id", id), {params})
            .then(res => res.data.data as ReimbursementsType[]);
    },

    createReimbursement: async (reimbursement: Partial<ReimbursementsType>) => {
        return http
            .post<AxiosResponse<ReimbursementsType>>(ADD_FETCH_REIMBURSEMENT_SALARY_ADVANCE_URL
                    .replace(":id", `${reimbursement.user}`)
                    .replace(":advance_id", `${reimbursement.salary_advance}`)
                , reimbursement);
    },

    getRemaningReimbursements: async (params: { month: string }) => {
        return http
            .get(FETCH_USERS_REMAINING_REIMBURSEMENTS_URL, {params})
            .then(res => res.data.data as RemainReimbursementType[]);
    },

    getUserRemainingReimbursement: async (id: string, params?: { month: string }) => {
        return http
            .get(FETCH_USER_REMAINING_REIMBURSEMENT_URL.replace(":id", id), {params})
            .then(res => res.data.data as RemainReimbursementType);
    }

};