import moment from "moment/min/moment-with-locales";
import { SettingsType } from "@/types/SettingsType";
import { User } from "@/types/UserType";
import { Moment } from "moment";
import {settingService} from "@/services/SettingService";

export const DEFAULT_PERIOD = { start: 25, end: 5 };

export const getClosingDateFromSettings = async () => settingService.getConstants("closing-date").then(res => res[0] as SettingsType<{
    start: number,
    end: number
}>);

export const searchInString = (searchArr: User[], searchString: string) => {
  return searchArr.filter((item: User) => {
    return (
      item.last_name +
      item.first_name
    )
      .toLowerCase()
      .includes(searchString.toLowerCase());
  });
};

// From API: Jan = 1, Feb = 2
// From Moment: Jan = 0, Feb = 1
export const nextClosingDate = (targetMonth: string, period?: { start: number, end: number }, format: string = "DD-MM-YYYY"): { startPeriod: Moment, endPeriod: Moment } => {
  if (!period) {
    period = DEFAULT_PERIOD;
  }

  let currentMonth = targetMonth.split("-")[0];
  let currentYear = targetMonth.split("-")[1];

  let start = moment(`${period.start}-${currentMonth}-${currentYear}`, format);

  return {
    startPeriod: start,
    endPeriod: moment(start).add(1, "months").set("date", period.end).endOf("day"),
  };
};

export const enableClosing = (period: { startPeriod: Moment, endPeriod: Moment } | undefined) => {
  if (!period) return false;
  return moment().isBetween(period.startPeriod, period.endPeriod, undefined, "[]");
};

export const defineTargetMonth = (lastClosingDate?: Date | null): string => {
  // No job (may be the first time)
  if (!lastClosingDate) return moment().format("MM-YYYY");

  // There is already a job done for this month so the target month is the month after
  return moment(lastClosingDate).add(1, "months").format("MM-YYYY");
};
