import React, {useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {getUserFullName, getUserPositionInCompany, isUserRegistrationCompleted} from "@/utils/utils";
import "./styles.scss";
import {User} from "@/types/UserType";
import Avatar from "comp_common/avatar/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import {Dialog, DialogActions, DialogContent} from "@mui/material";
import {Button} from "components/common";
import moment from "moment/moment";
import { useAppSelector } from "@/hooks/reduxToolkitHook";

export type CollaboItemProps = {
    className: string,
    collabo: User, isSelected?: boolean
    delUser: (user: User) => {}
}

export default function CollaboItem({className, collabo, isSelected, delUser}: CollaboItemProps) {
  const history = useHistory();
  const collaboFullName = getUserFullName(collabo, true);
  const isProfileComplete = collabo.is_active ?? isUserRegistrationCompleted(
      collabo.enrollment_status,
      collabo.is_old,
  );
    // const checkIsEndedContract = () => {
    //     if (!isProfileComplete) return false;
    //     const endContract = collabo.contract.end;
    //     if (!endContract) return false;
    //     return moment(endContract).diff(moment(), "weeks") <= 3;
    // };
    // const isEndedContract = useMemo(() => {
    //     return checkIsEndedContract()
    // }, [collabo]);


  const { targetMonth } = useAppSelector((state) => state.targetMonth);

  const endContract = collabo?.contract?.out_date || collabo.contract?.end;

  const targetMonthFirstDay = moment(`01-${targetMonth}`, "DD-MM-YYYY");
  const fifthOfMonthAfterTargetMonth = moment(`05-${targetMonth}`, "DD-MM-YYYY").add(1, "M").format("DD-MM-YYYY");

  const isContractEndInMonth = moment(endContract).isBetween(targetMonthFirstDay, moment(fifthOfMonthAfterTargetMonth, "DD-MM-YYYY")) &&
    moment(endContract).month() === targetMonthFirstDay.month();

  const [hover, setHover] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);


  function goToDetails() {
    if (!isProfileComplete) {
      history.push(`/ajout-collaborateur/${collabo.id}/processus`);
    } else {
      history.push({
        pathname: `/collaborateurs/${collabo.id}/profil`,
        state: collabo,
      });
    }
  }

  const StatusBadge = () => {
    if (!isProfileComplete) {
      return (
        <span className="py-[2px] px-2 rounded text-white bg-orange-500 text--caption !font-medium">
          En cours
        </span>
      );
    } else if (!collabo?.contract?.is_active) {
      return (
        <span className="py-[2px] px-2 rounded text-disabledText bg-disabledBg text--caption !font-medium">
          Sortie
        </span>
      );
    }
    else if (isContractEndInMonth) {
      return (
        <span className="py-[2px] px-2 rounded text-white bg-red-700 text--caption !font-medium">
          Sortie en cours du mois
        </span>
      );
    }
    return <></>;

  };

  return (
      <div
          className={`flex items-center w-full my-2 mx-auto ${className} hover:bg-blue-50 rounded p-2 cursor-pointer ${isSelected ? "!bg-blue-100" : ""} `}
          onClick={goToDetails}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
      >
          <Avatar user={collabo}/>
          <span className="ml-2 text-left truncate">
        <h4 className="word--wrap text--button text-gray-600">
          {collaboFullName}
        </h4>
        <p className="w-[12rem] text--caption text-gray-600 mb-1 word--wrap truncate">
          {getUserPositionInCompany(collabo)}
        </p>
            <StatusBadge />
      </span>

          <ConfirmForm handleClose={(v) => setConfirmOpen(!v)}
                       handleSubmit={() => delUser(collabo)}
                       confirmationText="Supprimer"
                       isOpen={confirmOpen}
                       collabo={collabo}
          />

          {hover && (
              <div className="ml-auto bg-red-200 text-center p-1" style={{width: "32px", borderRadius: "16px"}}>
                  <DeleteIcon className="text-red-400 bg-red-200" style={{width: "16px"}}
                              onClick={(e) => {
                                  setConfirmOpen(!confirmOpen);
                                  e.stopPropagation()
                                  setHover(false);
                              }}/>
              </div>
          )}

      </div>
  );
}

type ConfirmFormPros = {
  isOpen: boolean,
  handleClose: (v: boolean) => void,
  handleSubmit: (v: any) => void,
  confirmationText: string, collabo: User,
}

export const ConfirmForm = ({
                              isOpen,
                              handleSubmit,
                              handleClose,
                              confirmationText,
                              collabo,
                            }: ConfirmFormPros) => {
  const handlePaste = (e: any) => {
    e.preventDefault();
  };
  const [value, setValue] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const collaboFullName = getUserFullName(collabo, true);

  useEffect(() => {
    setValue("");
    setIsTouched(false);
  }, [isOpen]);

  return (
      <div>
          <Dialog open={isOpen} onClose={handleClose}>
              <div className="w-full flex items-center py-3 px-6 border-b-2 border-gray-100">
            <button
                className="ml-auto close-btn py-1"
                onClick={(_e) => {
                    handleClose(true);
                    setValue("");
                    setIsTouched(false);
                }}
            >
                <i className="ut-clear text-blue-[#575757] p-2 rounded-2xl"/>
            </button>
              </div>
              <DialogContent>
                  <div>
                      <h1>
                          Voulez-vous vraiment
                          supprimer le collaborateur
                          <strong> {collaboFullName} </strong>
                      </h1>
                      <br/>
                      <h2> Cette opération est irréversible. Si vous supprimez {collaboFullName},
                          vous perdrez toutes les donneés qui y sont liées.</h2>
                  </div>
                  <div className="flex flex-col">
                      <label className="text--submenu--noActif my-5">
                          {`Veuillez saisir le nom "Supprimer ${collabo?.usual_name}" pour confirmer la suppression.`}
                      </label>
                      <input
                          autoComplete="off"
                          onPaste={handlePaste}
                          value={value}
                          className={`form--custom w-full mt-2 ${
                              isTouched && value !== collabo?.last_name ? "!border-red-400" : ""
                          }`}
                          placeholder="Nom du collaborateur"
                          onChange={(e) => {
                              setIsTouched(true);
                              setValue(e.target.value);
                          }}
                      />
                  </div>
              </DialogContent>
              <DialogActions className="flex justify-end space-x-4">
                  <Button
                      buttonSize="btn--medium"
                      buttonStyle="btn--danger"
                      isDisabled={value !== "Supprimer " + collabo?.usual_name}
                      onClick={handleSubmit}
                  >
                      {confirmationText}
                  </Button>
                  <Button
                      buttonSize="btn--medium"
                      onClick={(_e) => {
                          handleClose(true);
                          setValue("");
                          setIsTouched(false);
                      }}
                  >
                      Annuler
                  </Button>
              </DialogActions>
          </Dialog>
      </div>
  );
};

