import {http} from "@/utils/http";
import {AxiosResponse} from "@/types/api/AxiosType";
import {DocumentCreationFormType, FetchUpdateDeleteEntryType, FileStructure} from "pag_collabo/folders/types";
import {
    ADD_FETCH_COMPANY_ROOT_DOCUMENTS_URL, FETCH_UPDATE_DELETE_DOCUMENT_URL,
    GET_DOCUMENT_CONTENT_URL, GET_DOCUMENT_VIEWERS_URL,
    GET_USER_DOCUMENTS_URL, RESTORE_DOCUMENT_URL, SHARE_DOCUMENT_URL
} from "@/constants/endpoints";

//TODO: check undefined params

export const documentService = {
    getAll: async (deleted?: boolean) => http.get<AxiosResponse<FileStructure[]>>(ADD_FETCH_COMPANY_ROOT_DOCUMENTS_URL, {params: {deleted}}),

    getByUserId: async (userId?: string, deleted?: boolean) => http
        .get<AxiosResponse<FileStructure[]>>(GET_USER_DOCUMENTS_URL.replace(":user_id", `${userId}`), {params: {deleted}}),

    getFolderContent: async (id?: string, deleted?: boolean, userId?: string) => http
        .get<AxiosResponse<FileStructure>>(GET_DOCUMENT_CONTENT_URL.replace(":id", `${id}`), {
            params: {
                deleted,
                userId
            }
        }),

    getFileContent: async (id: string) => http
        .get(
            GET_DOCUMENT_CONTENT_URL
                .replace("/:id/", `/${id}/`),
            {responseType: "blob", timeout: 30000}
        )
        .then(res => res),

    getViewers: async (id: string) => {
        return http
            .get(GET_DOCUMENT_VIEWERS_URL.replace("/:id/", `/${id}/`))
            .then(res => res.data.data);
    },

    create: async (document: Partial<DocumentCreationFormType | FormData>) => http.post<AxiosResponse<FileStructure>>(ADD_FETCH_COMPANY_ROOT_DOCUMENTS_URL.replace(':resource_id', "documents_create"), document),
    update: async ({id, document}: FetchUpdateDeleteEntryType) => http
        .patch<AxiosResponse<FileStructure>>(FETCH_UPDATE_DELETE_DOCUMENT_URL.replace(":id", `${id}`), document),
    share: ({
                id,
                document
            }: FetchUpdateDeleteEntryType) => http.post<AxiosResponse<FileStructure>>(SHARE_DOCUMENT_URL.replace(":id", `${id}`), document),
    delete: async (id?: string) => http.delete<AxiosResponse<FileStructure>>(FETCH_UPDATE_DELETE_DOCUMENT_URL.replace(":id", `${id}`)),
    restore: async (id?: string) => http.post<AxiosResponse<FileStructure>>(RESTORE_DOCUMENT_URL.replace(":id", `${id}`))
}