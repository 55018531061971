import {AxiosResponse} from "@/types/api/AxiosType";
import {
    AwardsTypes,
    UserAwardsFormType,
    UserAwardsType
} from "@/types/api/UserAwardsType";
import {
    ADD_AWARDS_TO_USER_URL,
    ADD_FETCH_PATCH_DELETE_USER_AWARDS_URL, AWARDS_GRANTED_BY_MONTH_URL, BULK_ADD_AWARDS_TO_USERS_URL,
    FETCH_AWARDS_TYPES_URL,
    FETCH_AWARDS_URL
} from "@/constants/endpoints";
import {http} from "@/utils/http";

export const awardService = {
    getAll: async (params?: { month?: string | null, filter_next?: boolean }) => {
        return http.get(FETCH_AWARDS_URL, {params})
            .then(res => res.data.data as UserAwardsType[]);
    },
    getNextByUserId: async (id: string, params?: { month?: string | null }) => {
        return http.get(ADD_FETCH_PATCH_DELETE_USER_AWARDS_URL.replace(":id", id), {
            params: {
                ...params,
                filter_next: true
            }
        })
            .then(res => res.data.data as UserAwardsType[]);
    },
    getGrantedByMonth: async ({month}: { month?: string | null }) => {
        return http
            .get(AWARDS_GRANTED_BY_MONTH_URL, {params: {month}})
            .then(res => res.data.data as UserAwardsType[]);
    },
    getTypes: async () => http.get<AxiosResponse<AwardsTypes[]>>(FETCH_AWARDS_TYPES_URL),
    getByUserId: async (id: string, params?: {
        month?: string
    }) => http.get(ADD_FETCH_PATCH_DELETE_USER_AWARDS_URL.replace(":id", id), {params}),
    bulkCreate: async (award: Partial<UserAwardsFormType>) => http.post<AxiosResponse<UserAwardsType[]>>(BULK_ADD_AWARDS_TO_USERS_URL, award),
    create: async (award: Partial<UserAwardsFormType>) => http.post<AxiosResponse<UserAwardsType>>(ADD_AWARDS_TO_USER_URL.replace(":id", `${award.user}`), award),
    delete: async (pk: string, id: string) => http
        .delete<AxiosResponse<UserAwardsType>>(ADD_FETCH_PATCH_DELETE_USER_AWARDS_URL.replace(":id", pk) + id)
};