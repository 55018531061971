import {http} from "@/utils/http";
import {
    ADD_ABSENCES_TO_USERS_URL,
    ADD_FETCH_USER_ABSENCES_URL,
    FETCH_ABSENCES_DAILY_STATS_URL,
    FETCH_ABSENCES_TYPES_URL,
    FETCH_ABSENCES_URL,
    FETCH_LEAVES_BALANCES_URL,
    FETCH_UPDATE_DELETE_ABSENCES_URL,
    FETCH_USER_LEAVE_BALANCE_URL,
    FETCH_USERS_LAST_AND_NEXT_ABSENCES
} from "@/constants/endpoints";
import {AxiosResponse} from "@/types/api/AxiosType";
import {AbsencesTypes, LastAndNextAbsenceType, LeaveBalanceType, UserAbsenceType} from "@/types/api/UserAbsenceType";

export type GetAbsences = {
    filter_by?: string,
    month?: string
}

export const absenceService = {
    create: async (data: any) => {
        return http.post(ADD_ABSENCES_TO_USERS_URL, data).then(res => res.data.data);
    },
    update: async (data: any) => {
        return http.patch(FETCH_UPDATE_DELETE_ABSENCES_URL.replace(":id", data.get("id")), data).then(res => res.data.data);
    },
    get: async (params?: GetAbsences) => {
        if (!params) {
            return http.get<AxiosResponse<UserAbsenceType[]>>(FETCH_ABSENCES_URL).then(res => res.data.data);
        }
        return http.get<AxiosResponse<UserAbsenceType[]>>(FETCH_ABSENCES_URL, {params}).then(res => res.data.data);
    },
    delete: async (id: string) => http.delete(FETCH_UPDATE_DELETE_ABSENCES_URL.replace(":id", id)),
    getTypes: async () => http.get<AxiosResponse<AbsencesTypes[]>>(FETCH_ABSENCES_TYPES_URL).then(res => res.data.data),
    getByMonth: (month: string) => http.get<AxiosResponse<UserAbsenceType[]>>(FETCH_ABSENCES_URL + `?month=${month}`),
    getByUserIdAndMonth: async (params: {
        id: string,
        month: string
    }) => {
        return http.get<AxiosResponse<UserAbsenceType[]>>(ADD_FETCH_USER_ABSENCES_URL.replace(":id", params.id) + `?month=${params.month}`)
            .then(res => res.data.data);
    },
    getLeavesBalances: async () => {
        return http.get(FETCH_LEAVES_BALANCES_URL)
            .then(res => res.data.data as LeaveBalanceType[]);
    },
    getUserLeaveBalance: async (id: string) => {
        return http.get(FETCH_USER_LEAVE_BALANCE_URL.replace(":id", id))
            .then(res => res.data.data as LeaveBalanceType[]);
    },
    getAbsencesStatistics: async () => {
        return http.get(FETCH_ABSENCES_DAILY_STATS_URL)
            .then(res => res.data.data as { abs_type: string, count: number }[]);
    },
    getUsersLastAndNextAbsences: async (params: { type_id: string }) => {
        return http.get(
            FETCH_USERS_LAST_AND_NEXT_ABSENCES,
            {
                params: {
                    type_id: params.type_id
                }
            }
        ).then(res => res.data.data as LastAndNextAbsenceType);
    }
};