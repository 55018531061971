import React, {useState} from "react";
import {FormInfoBlue, ModalForm} from "components/common";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import {useHistory, useParams} from "react-router-dom";
import {Checkbox, FormControlLabel} from "@mui/material";

/**
 *  This component render the modal that contain the form to end collabo contract.
 *
 * @param {open, onModalClose, modalTitle}
 * @returns component that is modal to render the form to add collabo
 */

export default function EndContractForm(
    {
      open,
      onModalClose,
      userContractType,
      userId=null
    }) {
  const [accept, setAccept] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  const handleFormSubmit = () => {
    if (accept) {
      onModalClose();
      history.push(`/ajout-collaborateur/${id ?? userId}/fin-de-contrat/motif`);
    }
  };

  return (
      <div>
        <ModalForm
            open={open}
            modalWidth="xs"
            onModalClose={onModalClose}
            modalTitle={<><LightbulbIcon color={"primary"} />Bon à savoir</>}
            submitButtonLabel="Continuer"
            formSubmit={handleFormSubmit}
            cancelButtonLabel="Abandonner"
            submitButtonProps={{ disabled: !accept }}
        >
          {
            userContractType === "CDI" ?
                <div className="ml-12 mr-6 mt-2 space-y-5">
                  <ol className="list-decimal space-y-4">
                    <div className="space-y-1">
                      <li className="text-base font-semibold pt-3">Procédure de licenciement</li>
                      <ul className="list-disc ml-4">
                        <li>Le licenciement doit intervenir pour motif légitime.</li>
                        <li>Le licenciement doit être notifié au travailleur par écrit avec indication du motif.</li>
                        <li>Un salarié ne peut être licencié sans avoir eu l'opportunité de se défendre, sauf en cas de
                          licenciement économique collectif.
                        </li>
                          <li>Le licenciement nécessite un préavis.</li>
                      </ul>
                    </div>
                    <div className="space-y-1">
                      <li className="text-base font-semibold">Licenciement irrégulier</li>
                      <dl className="space-y-2">
                        <dd>
                          Un licenciement pour motif légitime, sans notification ou indication du motif, sans possibilité
                          pour le salarié de s'expliquer, ou survenu pendant le congé, est irrégulier mais non abusif. La
                          juridiction du travail peut accorder au travailleur une indemnité allant jusqu’à 2 mois de salaire
                        </dd>
                      </dl>
                    </div>
                    <div className="space-y-1">
                      <li className="text-base font-semibold">Licenciement abusif</li>
                      <dl className="space-y-2">
                        <dd>
                          Le licenciement peut être considéré comme abusif par la juridiction du travail s'il n'est pas
                          justifié par une cause réelle et sérieuse ou s'il n'est pas basé sur des motifs légitimes. Dans ce
                          cas, vous pourriez être condamné à verser une indemnité, dont le montant ne peut être inférieur à
                          trois mois de salaire brut et ne peut dépasser neuf mois
                        </dd>
                      </dl>
                    </div>
                  </ol>

                  <FormInfoBlue
                      className="col-span-12 border-solid border-blue-500 border-[1px]"
                      infoText="Uptimise n'est pas impliqué dans la détermination ni la qualification du motif de licenciement, ni dans le processus de licenciement. Les informations qui vous seront affichées dans le parcours suivant sont à titre informatif. Uptimise se base uniquement sur les informations et données que vous avez saisies pour déterminer les droits du salarié."
                      type="danger"
                  />

                  <FormControlLabel className="pb-3"
                                    control={
                                      <Checkbox value={accept} onChange={() => setAccept(!accept)} required />
                                    }
                                    label="J'ai lu et j'ai compris"
                  />
                </div>
                :
                <div className="ml-12 mr-6 mt-2 space-y-5">
                  <ol className="list-decimal space-y-4">
                    <div className="space-y-1">
                      <li className="text-base font-semibold pt-3">La cessation d'un contrat à durée déterminée</li>
                      <dl className="space-y-2">
                        <dd>
                          Un contrat de travail à durée déterminée (CDD) ne peut être rompu avant son terme que pour
                          force majeure, accord mutuel ou faute lourde de l’une des parties. Il peut aussi être interrompu
                          pendant la période d’essai.
                        </dd>
                        <dd>
                          Toute rupture ne respectant pas ces règles ouvre droit à des dommages et intérêts pour la partie
                          lésée, correspondant aux salaires et avantages que le salarié aurait perçus jusqu’à la fin du contrat.
                        </dd>
                        <dd>
                          Toutefois, si le CDD a été conclu pour remplacer un salarié temporairement absent, il peut être
                          rompu unilatéralement par le salarié après six mois d’exécution. (Cf article 15.9, Code du travail)
                        </dd>
                      </dl>
                    </div>
                    <div className="space-y-1">
                      <li className="text-base font-semibold">Les documents établis lors de la séparation</li>
                      <dl className="space-y-2">
                        <dd>
                          Lorsque le contrat de travail prend fin, quel que soit son type, l’employeur doit remettre au
                          salarié certains documents justifiant des informations essentielles telles que la date d’entrée
                          et de sortie de l’entreprise, ainsi que la nature des emplois occupés et leur classification professionnelle.
                          Ces documents incluent notamment:
                        </dd>
                        <dd className="ml-4">
                          <ul className="list-disc">
                            <li>
                              Un certificat de travail
                            </li>
                            <li>
                              Un relevé nominatif de salaire
                            </li>
                          </ul>
                        </dd>
                      </dl>
                    </div>
                  </ol>

                  <FormInfoBlue
                      className="col-span-12 border-solid border-blue-500 border-[1px]"
                      infoText="Uptimise n'est pas impliqué dans la détermination ni la qualification du motif de licenciement, ni dans le processus de licenciement. Les informations qui vous seront affichées dans le parcours suivant sont à titre informatif. Uptimise se base uniquement sur les informations et données que vous avez saisies pour déterminer les droits du salarié."
                      type="danger"
                  />

                  <FormControlLabel className="pb-3"
                                    control={
                                      <Checkbox value={accept} onChange={() => setAccept(!accept)} required />
                                    }
                                    label="J'ai lu et j'ai compris"
                  />
                </div>
          }
        </ModalForm>
      </div>
  );
}
