import { createTheme, keyframes } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { frFR } from "@mui/material/locale";
import { PaletteColorOptions } from "@mui/material";

export const blue = {
  50: "#F7F9FA",
  100: "#E8F4F8",
  200: "#CCE4EA",
  300: "#A8D1DC",
  400: "#7BC3D5",
  500: "#399AB3",
  600: "#2E7B8F",
  700: "#225C6B",
  800: "#173E48",
  900: "#0E282F",
};

const btnFlash = keyframes`
  from {
    outline: 2px solid white;
    outline-offset: 0;
  }

  to {
    outline: 2px solid transparent;
    outline-offset: -6px;
  }
`;

const Theme = createTheme({
  // example de couleur custom
  customColor: {
    grey: grey[50],
  },
  typography: {
    fontFamily: ["acumin-pro", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      contrastText: "#FFFFFF",
      main: blue[500],
      light: blue[200],
      dark: blue[700],
    },
    secondary: {
      main: "#173E48",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#f13153",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#21a871",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#F4921B",
      contrastText: "#FDEDD9",
    },
    archived: {
      main: "#A9A9A9",
      contrastText: "#FFFFFF",
    },
  },
  components: {
    // Avatar
    MuiAvatar: {
      styleOverrides: {
        root: {
          "&.md": {
            fontSize: "16px",
          },
          "&.lg": {
            fontSize: "24px",
            width: "128px",
            height: "128px",
          },
        },
      },
    },

    // Button
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          boxShadow: "none",
          letterSpacing: "initial",
          fontWeight: 500,
          fontSize: "15px",
          textTransform: "inherit",
          padding: "0.5rem 1rem",
          lineHeight: "1.5",
          borderWidth: "1px",
          "&:hover": {
            boxShadow: "none",
            borderColor: "transparent",
          },
          "&:active": {
            animationName: btnFlash,
            animationDuration: "0.5s",
          },
          "&.MuiButton-outlinedPrimary": {
            color: "#000000",
            "&:hover": {
              color: "#ffffff",
              backgroundColor: blue["500"],
            },
          },
        },
      },
    },

    // Chip
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: "600",
          paddingLeft: "0.5rem",
          paddingRight: "0.5rem",
        },
      },
    },

    // Collapse
    MuiCollapse: {
      styleOverrides: {
        root: {
          ".MuiTableCell-body": {
            padding: "8.5px 12px",
          },
        },
      },
    },

    //Tab
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "inherit",
          "&.Mui-selected": {
            color: "#000000",
          },
        },
      },
    },

    //Table Container
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "4px",
          ".MuiTableRow-root": {},
          ".MuiTableRow-head, .MuiTableCell-stickyHeader": {
            background: "#d8edf3",
            overflow: "initial",
          },
          ".MuiTableCell-head": {
            fontWeight: 600,
            fontSize: "14px",
          },
          ".MuiTableCell-body": {
            maxHeight: "52px",
            minHeight: "52px",
            padding: "13.75px 16px",
          },
        },
      },
    },

    //Switch
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: "40px",
          height: "20px",
          margin: "10px",
          padding: "0",
          borderRadius: "10px",
          ".MuiSwitch-switchBase": {
            padding: "3px",
          },
          ".MuiSwitch-input": {
            width: "100%",
            height: "100%",
          },
          ".MuiSwitch-thumb": {
            width: "14px",
            height: "14px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiFocused": {
            borderOpacity: "0 !important",
          },
          ".MuiAutocomplete-inputRoot": {
            padding: "0 !important",
          },
        },
      },
    },

    // DataGrid
    // @ts-ignore
    MuiDataGrid: {
      styleOverrides: {
        root: {
          ".MuiDataGrid-columnHeaders": {
            background: "#d8edf3",
          },
          ".MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        },
      },
    },
  },
}, frFR);

declare module "@mui/material/styles" {
  interface Theme {
    customColor: {
      grey: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    customColor?: {
      grey?: string;
    };
  }
}

declare module "@mui/material/styles" {
  interface PaletteOptions {
    archived: PaletteColorOptions;
  }
}

export default Theme;
