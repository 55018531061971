import moment from "moment/min/moment-with-locales";
import {NotificationEvent, NotificationItemType} from "pag_collabo/notifications/NotificationItem";
import {User} from "@/types/UserType";

moment.locale("fr");

export const timeElapsed = (date: any) => {
    // const now = moment();
    // const secondsPast = Math.floor((now - date) / 1000);

    const secondsPast = moment().diff(date,"seconds")
    if (secondsPast < 60) {
        return `${secondsPast} ` + (secondsPast < 1 ? "seconde": "secondes");
    }
    if (secondsPast < 3600) {
        const minutes = Math.floor(secondsPast / 60);
        return `${minutes} `  + (secondsPast < 1 ? "minute": "minutes");
    }
    if (secondsPast < 86400) {
        const hours = Math.floor(secondsPast / 3600);
        return `${hours} ` + (secondsPast < 1 ? "heure": "heures");
    }
    if (secondsPast < 2592000) {
        const days = Math.floor(secondsPast / 86400);
        return `${days} ` + (secondsPast < 1 ? "jour": "jours");
    }
    const months = Math.floor(secondsPast / 2592000);
    return `${months} mois`;
}

export const getActionCustomMessage = (notification: NotificationItemType, user: User) => {
    const {name, end_date} = notification;

    if (name == NotificationEvent.endContract) {
        return `Le contrat de ${user!.first_name} ${user!.last_name} expire le ${moment(end_date).format("dddd DD MMMM YYYY")}`;
    }
    if (name == NotificationEvent.endInterShip) {
        return `Le stage de ${user!.first_name} ${user!.last_name} prend fin le ${moment(end_date).format("dddd DD MMMM YYYY")}`;
    }
    if (name == NotificationEvent.endTrial) {
        return `La période d'essai de  ${user!.first_name} ${user!.last_name} prend fin le ${moment(end_date).format("dddd DD MMMM YYYY")}`;
    }
    return ``;
};

export const isDueDatePassed = (dueDate: Date) => {
    return moment(dueDate).isSameOrAfter(moment());
};

export const getValidNotifications = (notifications: NotificationItemType[], users: User[]) => {
    return notifications.filter(notification => {
        const user = users.find(u => notification.user == u.id);
        if (user) {
            return notification;
        }
    });


}