import {http} from "@/utils/http";
import {
    ADD_FETCH_BANK_URL,
    ADD_FETCH_SIGNATORY_URL,
    FETCH_PATCH_DELETE_BANK_URL,
    FETCH_PATCH_DELETE_SIGNATORY_URL
} from "@/constants/endpoints";
//TODO: set correct type
export const bankService = {
    getAll: () => http(ADD_FETCH_BANK_URL),
    getAllSignatories: () => http.get(ADD_FETCH_SIGNATORY_URL),
    create: async (banks: any) => http.post(ADD_FETCH_BANK_URL, banks),
    createSignatory: async (signatory: any) => http.post(ADD_FETCH_SIGNATORY_URL, signatory),
    update: async (id: string, data: any) => http.patch(FETCH_PATCH_DELETE_BANK_URL.replace(":id", id), data),
    delete: async (id: string) => http.delete(FETCH_PATCH_DELETE_BANK_URL.replace(":id", id)),
    deleteSignatory: async (id: string) => http.delete(FETCH_PATCH_DELETE_SIGNATORY_URL.replace(":id", id))
}