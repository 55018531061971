import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import {Radio} from "@mui/material";
import {RadioButtonChecked, RadioButtonUnchecked} from "@mui/icons-material";

const Filter = ({onFilter, isFiltered}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(Boolean(anchorEl));
    const [isChecked, setIsChecked] = useState(0);
    const [isSelected, setIsSelected] = useState(0);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const filterOption = [
        {label: "Actif", value: "actif", field: "status"},
        {label: "En cours", value: "pending", field: "status"},
        {label: "Sortie", value: "sortie", field: "status"},
        {label: "Action requise", value: "ending", field: "status"},
    ];

    const userFilterOption = [
        {label: "Tous", value: "none", field: "type"},
        {label: "Salariés", value: "SALARIE", field: "type"},
        {label: "Prestataires", value: "PRESTATAIRE", field: "type"},
        {label: "Stagiaires", value: "STAGIAIRE", field: "type"},
    ];

    const handleUserFilter = (i, item) => {
        setIsSelected(i)
        setOpen(false);
        onFilter(item);
    };

    const handleFilter = (i, item) => {
        setIsChecked(i)
        setOpen(false);
        onFilter(item);
    };

    useEffect(() => {
        setIsSelected(0)
        setIsChecked(0)
    }, [isFiltered]);

    const radioIcon = <RadioButtonUnchecked fontSize="small"/>;
    const radioCheckedIcon = <RadioButtonChecked fontSize="small"/>;

    const CustomMenu = styled((props) => (
        <Menu elevation={0}
              anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
              }}
              transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
              }}{...props} />
    ))(({theme}) => ({
        "& .MuiPaper-root": {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color:
                theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
            boxShadow:
                "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            "& .MuiMenu-list": {
                padding: "4px 0",
            },
        },
    }));


    const FilterIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" x="0" y="0" viewBox="0 0 32 32">
                <g>
                    <path d="m2 6h14v-2h-14a1 1 0 0 0 0 2z" fill="#000000"/>
                    <path d="m30 4h-4a3 3 0 0 0 -3-3h-2a3 3 0 0 0 -3 3v2a3 3 0 0 0 3 3h2a3 3 0 0 0 3-3h4a1 1 0 0 0 0-2z"
                          fill="#000000"/>
                    <path
                        d="m30 26h-9a3 3 0 0 0 -3-3h-2a3 3 0 0 0 -3 3v2a3 3 0 0 0 3 3h2a3 3 0 0 0 3-3h9a1 1 0 0 0 0-2z"
                        fill="#000000"/>
                    <path d="m2 28h9v-2h-9a1 1 0 0 0 0 2z" fill="#000000"/>
                    <path d="m30 15h-15v2h15a1 1 0 0 0 0-2z" fill="#000000"/>
                    <path
                        d="m2 17h3a3 3 0 0 0 3 3h2a3 3 0 0 0 3-3v-2a3 3 0 0 0 -3-3h-2a3 3 0 0 0 -3 3h-3a1 1 0 0 0 0 2z"
                        fill="#000000"/>
                </g>
            </svg>
        );
    };

    return (
        <>
            <button className="border-[1px] border-blue-200 rounded py-2 px-3 ml-2"
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}>
                <FilterIcon/>
            </button>
            <CustomMenu open={open} onClose={handleClose} anchorEl={anchorEl}>
                <p className="ml-3 text--button text--S6">Types de contrat</p>
                {userFilterOption.map((item, i) => (
                    <li key={i}>
                        <Radio icon={radioIcon} checkedIcon={radioCheckedIcon} style={{marginRight: 8}}
                               checked={isSelected === i}
                               onChange={() => handleUserFilter(i, item)}/>
                        {item.label}
                    </li>
                ))}
                <hr className="mt-2 my-2"/>
                <p className="ml-3 text--button">Status</p>
                {filterOption.map((item, i) => (
                    <li key={i}>
                        <Radio checkedIcon={radioCheckedIcon} style={{marginRight: 8}} checked={isChecked === i}
                               onChange={() => handleFilter(i, item)}/>

                        {item.label}
                    </li>
                ))}

            </CustomMenu>
        </>
    );
};

export default Filter;
