import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { AxiosResponse, CustomError, ErrorResponse } from "@/types/api/AxiosType";
import { JobType } from "@/types/SettingsType";
import { UserPaysheet } from "@/types/paysheetDataType";
import {paysheetService} from "@/services/PaysheetService";

// TODO: rm this resquest from redux and make it simple api call later
export const fetchPaysheetGenerationJob = createAsyncThunk("GET_PAYSHEETS_JOB", async (_, thunkApi) => {
    return paysheetService.getPaysheetsGenerationJob()
    .then(data => data)
    .catch(e => {
      const error = e as AxiosError<CustomError>;
      const response = error.response as unknown as AxiosResponse<ErrorResponse>;
      return thunkApi.rejectWithValue({ error: response.data });
    });
});

export const fetchUserPaysheets = createAsyncThunk("GET_USER_PAYSHEETS", async (args: { id: string, params?: { month?: string } }, thunkApi) => {
    return paysheetService.getUserPaysheets(args.id, args.params)
    .then(data => data)
    .catch(e => {
      const error = e as AxiosError<CustomError>;
      const response = error.response as unknown as AxiosResponse<ErrorResponse>;
      return thunkApi.rejectWithValue({ error: response.data });
    });
});

const initialState: {
  paysheetsGenerationJob: JobType | undefined | null,
  paysheetsGenerationStatus: string,
  paysheetsGenerationErrors?: any,

  paysheets: UserPaysheet[],
  paysheetsStatus: string,
  paysheetsErrors?: any
} = {
  paysheetsGenerationJob: undefined,
  paysheetsGenerationStatus: "idle",

  paysheets: [],
  paysheetsStatus: "idle",
};

const paysheetsSlice = createSlice({
  name: "paysheets_generation_job",
  initialState,
  reducers: {
    // update paysheets generation job content
    updatePaysheetsGenerationJob: (state, action: PayloadAction<JobType>) => {
      state.paysheetsGenerationJob = action.payload;
    },
  },
  extraReducers(builder) {
    // get paysheets generation job
    builder
      .addCase(fetchPaysheetGenerationJob.pending, (state) => {
        state.paysheetsGenerationStatus = "loading";
      })
      .addCase(fetchPaysheetGenerationJob.fulfilled, (state, action) => {
        state.paysheetsGenerationJob = action.payload;
        state.paysheetsGenerationStatus = "succeeded";
      })
      .addCase(fetchPaysheetGenerationJob.rejected, (state, action) => {
        state.paysheetsGenerationErrors = action.error;
        state.paysheetsGenerationStatus = "failed";
      });

    // get user's paysheets
    builder
      .addCase(fetchUserPaysheets.pending, (state) => {
        state.paysheetsStatus = "loading";
      })
      .addCase(fetchUserPaysheets.fulfilled, (state, action) => {
        state.paysheetsStatus = "succeeded";
        state.paysheets = action.payload;
      })
      .addCase(fetchUserPaysheets.rejected, (state, action) => {
        state.paysheetsStatus = "failed";
        state.paysheetsErrors = action.error;
      });

  },
});

export const { updatePaysheetsGenerationJob } = paysheetsSlice.actions;
export default paysheetsSlice.reducer;