import {http} from "@/utils/http";
import {AxiosResponse} from "@/types/api/AxiosType";
import {TeamType} from "@/types/TeamsType";
import {FETCH_PATCH_DELETE_TEAMS_URL, REMOVE_MEMBER_TEAMS_URL} from "@/constants/endpoints";
import {getFormData} from "@/utils/utils";

export const teamService = {
    create: async (data: any) => {
        return http.post<AxiosResponse<TeamType>>(FETCH_PATCH_DELETE_TEAMS_URL, getFormData(data))
    },
    getAll: () => http.get<AxiosResponse<TeamType[]>>(FETCH_PATCH_DELETE_TEAMS_URL)
        .then(res => res.data.data),
    update: async (id: string, data: any) => {
        return http.patch<AxiosResponse<TeamType>>(`${FETCH_PATCH_DELETE_TEAMS_URL}${id}/`, getFormData(data));

    },
    getById: (id: string) => http.get<AxiosResponse<TeamType>>(`${FETCH_PATCH_DELETE_TEAMS_URL + id}/`),
    delete: async (id: string) => {
        return http.delete<AxiosResponse>(`${FETCH_PATCH_DELETE_TEAMS_URL}${id}/`);
    },
    removeMember: async (teamId: string, userId: string) => {
        return http.delete(`${REMOVE_MEMBER_TEAMS_URL.replace(':id', teamId)}${userId}/`);
    }
}