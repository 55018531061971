import { http } from "@/utils/http";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ADD_FETCH_COMPANY_ROOT_DOCUMENTS_URL,
  FETCH_UPDATE_DELETE_DOCUMENT_URL,
  GET_DOCUMENT_CONTENT_URL,
  GET_USER_DOCUMENTS_URL,
  RESTORE_DOCUMENT_URL,
  SHARE_DOCUMENT_URL,
} from "@/constants/endpoints";
import { AxiosResponse } from "@/types/api/AxiosType";
import {
  DeletedAttrType,
  DocumentCreationFormType,
  FetchUpdateDeleteEntryType,
  FileParent,
  FileStructure,
} from "pag_collabo/folders/types";
import {documentService} from "@/services/DocumentService";

export const fetchDocuments = createAsyncThunk("document/getAsyncDocuments",
    async ({deleted}: DeletedAttrType, thunkApi) => {
        return documentService.getAll(deleted)
            .then(res => res.data)
            .catch(e => {
                return thunkApi.rejectWithValue({data: e.data});
            });
    });

export const fetchUserDocuments = createAsyncThunk("document/getUserAsyncDocuments",
    async ({user_id, deleted}: FetchUpdateDeleteEntryType & Partial<DeletedAttrType>, thunkApi) => {
        return documentService.getByUserId(user_id, deleted)
            .then(res => res.data)
            .catch(e => {
                return thunkApi.rejectWithValue({data: e.data});
            });
    });

export const fetchDocumentContent = createAsyncThunk("document/getAsyncDocumentContent",
    async ({id, deleted, user_id}: FetchUpdateDeleteEntryType & Partial<DeletedAttrType>, thunkApi) => {
        return documentService.getFolderContent(id, deleted, user_id)
            .then(res => res.data)
            .catch(e => {
                return thunkApi.rejectWithValue({data: e.data});
            });
    });


export const createAsyncDocument = createAsyncThunk<AxiosResponse<FileStructure>, Partial<DocumentCreationFormType | FormData>>(
    "document/createAsyncDocument",
    async (document: Partial<DocumentCreationFormType | FormData>, thunkApi) => {
        return documentService.create(document)
            .then(res => res.data)
            .catch(e => {
                return thunkApi.rejectWithValue({data: e.data});
            });
    },
);

export const updateAsyncDocument = createAsyncThunk<AxiosResponse<FileStructure>, FetchUpdateDeleteEntryType>(
    "document/updateAsyncDocument",
    async ({id, document}: FetchUpdateDeleteEntryType, thunkApi) => {
        return documentService.update({id, document})
            .then(res => res.data)
            .catch(e => {
                return thunkApi.rejectWithValue({data: e.data});
            });
    },
);

export const shareAsyncDocument = createAsyncThunk<AxiosResponse<FileStructure>, FetchUpdateDeleteEntryType>(
    "document/shareAsyncDocument",
    async ({id, document}: FetchUpdateDeleteEntryType, thunkApi) => {
        return documentService.share({id, document})
            .then(res => res.data)
            .catch(e => {
                return thunkApi.rejectWithValue({data: e.data});
            });
    },
);

export const deleteAsyncDocument = createAsyncThunk<AxiosResponse<FileStructure>, FetchUpdateDeleteEntryType>(
    "document/deleteAsyncDocument",
    async ({id}: FetchUpdateDeleteEntryType, thunkApi) => {
        return documentService.delete(id)
            .then(res => res.data)
            .catch(e => {
                return thunkApi.rejectWithValue({data: e.data});
            });
    },
);

export const restoreAsyncDocument = createAsyncThunk<AxiosResponse<FileStructure>, FetchUpdateDeleteEntryType>(
    "document/restoreAsyncDocument",
    async ({id}: FetchUpdateDeleteEntryType, thunkApi) => {
        return documentService.restore(id)
            .then(res => res.data)
            .catch(e => {
                return thunkApi.rejectWithValue({data: e.data});
            });
    },
);


// initial state
const initialState: {
  documents: FileStructure[],
  filesTree: FileParent[],
  documentsStatus: string,
  documentsErrors?: any,

  addDocumentStatus: string,
  addDocumentErrors?: any,

  updateDocumentStatus: string,
  updateDocumentErrors?: any,

  shareDocumentErrors?: any,
  shareDocumentStatus: string,

  deleteDocumentErrors?: any,
  deleteDocumentStatus: string,

  restoreDocumentErrors?: any,

  restoreDocumentStatus: string,
} = {
  documents: [],

  filesTree: [],

  documentsStatus: "idle",

  addDocumentStatus: "idle",

  updateDocumentStatus: "idle",

  shareDocumentStatus: "idle",

  restoreDocumentStatus: "idle",

  deleteDocumentStatus: "idle",
};

const documentsSlice = createSlice({
  name: "document",
  initialState,
  reducers: {},

  extraReducers(builder) {
    // get  all documents
    builder
      .addCase(fetchDocuments.pending, (state) => {
        state.documentsStatus = "loading";
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.documentsStatus = "succeeded";
        state.documents = action.payload.data;
        state.filesTree = [];
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.documentsStatus = "failed";
        state.documentsErrors = action.error;
      });
    builder
      .addCase(fetchUserDocuments.pending, (state) => {
        state.documentsStatus = "loading";
      })
      .addCase(fetchUserDocuments.fulfilled, (state, action) => {
        state.documentsStatus = "succeeded";
        state.documents = action.payload.data;
        state.filesTree = [];
      })
      .addCase(fetchUserDocuments.rejected, (state, action) => {
        state.documentsStatus = "failed";
        state.documentsErrors = action.error;
      });

    builder
      .addCase(fetchDocumentContent.pending, (state) => {
        state.documentsStatus = "loading";
      })
      .addCase(fetchDocumentContent.fulfilled, (state, action) => {
        state.documentsStatus = "succeeded";
        state.documents = action.payload.data.sub_folders as FileStructure[];
        state.filesTree = [
          ...action.payload.data.parents_tree,
          { id: action.payload.data.id, name: action.payload.data.name },
        ] as FileParent[];
      })
      .addCase(fetchDocumentContent.rejected, (state, action) => {
        state.documentsStatus = "failed";
        state.documentsErrors = action.error;
      });

    builder
      .addCase(createAsyncDocument.pending, (state) => {
        state.addDocumentStatus = "loading";
      })
      .addCase(createAsyncDocument.fulfilled, (state, action) => {
        state.addDocumentStatus = "succeeded";
        state.documents = [...state.documents, action.payload.data];

      })
      .addCase(createAsyncDocument.rejected, (state, action) => {
        state.addDocumentStatus = "failed";
        state.addDocumentErrors = action.error;
      });

    builder
      .addCase(restoreAsyncDocument.pending, (state) => {
        state.restoreDocumentStatus = "loading";
      })
      .addCase(restoreAsyncDocument.fulfilled, (state, action) => {
        state.restoreDocumentStatus = "succeeded";
        state.documents = state.documents.filter((document) => document.id !== action.meta.arg.id);
      })
      .addCase(restoreAsyncDocument.rejected, (state, action) => {
        state.restoreDocumentStatus = "failed";
        state.restoreDocumentErrors = action.error;
      });

    builder
      .addCase(updateAsyncDocument.pending, (state) => {
        state.updateDocumentStatus = "loading";
      })
      .addCase(updateAsyncDocument.fulfilled, (state, action) => {
        state.updateDocumentStatus = "succeeded";
        let index = state.documents.findIndex(document => document.id === action.meta.arg.id);
        if (index >= 0) {
          state.documents.splice(index, 1);
          state.documents.unshift(action.payload.data);
        }
      })
      .addCase(updateAsyncDocument.rejected, (state, action) => {
        state.updateDocumentStatus = "failed";
        state.updateDocumentErrors = action.error;
      });

    builder
      .addCase(shareAsyncDocument.pending, (state) => {
        state.shareDocumentStatus = "loading";
      })
      .addCase(shareAsyncDocument.fulfilled, (state) => {
        state.shareDocumentStatus = "succeeded";
        // let index = state.documents.findIndex(document => document.id === action.meta.arg.id);
        // if (index >= 0) {
        //   state.documents.splice(index, 1);
        //   state.documents.unshift(action.payload.data);
        // }
      })
      .addCase(shareAsyncDocument.rejected, (state, action) => {
        state.shareDocumentStatus = "failed";
        state.shareDocumentErrors = action.error;
      });

    builder
      .addCase(deleteAsyncDocument.pending, (state) => {
        state.deleteDocumentStatus = "loading";
      })
      .addCase(deleteAsyncDocument.fulfilled, (state, action) => {
        state.deleteDocumentStatus = "succeeded";
        state.documents = state.documents.filter((document) => document.id !== action.meta.arg.id);
      })
      .addCase(deleteAsyncDocument.rejected, (state, action) => {
        state.deleteDocumentStatus = "failed";
        state.deleteDocumentErrors = action.error;
      });

  },
});

export default documentsSlice.reducer;

