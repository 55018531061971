import {http} from "@/utils/http";
import {
    ADD_FETCH_ACOMPTES_USERS_URL,
    FETCH_ALL_ACOMPTES_URL,
    FETCH_PATCH_DELETE_ACOMPTES_URL
} from "@/constants/endpoints";
import {AcompteType} from "@/types/api/UserSalaryAdvancesType";
import {AxiosResponse} from "@/types/api/AxiosType";
import {getFormData} from "@/utils/utils";

export const acompteService = {
    create: async (acompte: Partial<AcompteType>) => {
        return http.post<AxiosResponse<AcompteType>>(ADD_FETCH_ACOMPTES_USERS_URL.replace(":id", `${acompte.user}`), getFormData(acompte));
    },
    delete: async (id: string) => {
        return http.delete(FETCH_PATCH_DELETE_ACOMPTES_URL.replace(":id", id));
    },
    getAll: async (params?: { month?: string | null, filter_by?: string }) => {
        return http.get(FETCH_ALL_ACOMPTES_URL, {params}).then(res => res.data);
    },
    getByUserId: async (id: string, params?: { month: string }) => {
        return http.get(ADD_FETCH_ACOMPTES_USERS_URL.replace(":id", id), {params})
            .then(res => res.data.data as AcompteType[]);
    }
};